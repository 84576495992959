<template>
  <!--pages/manager/city/index.wxml-->
  <div class="city-manager">
    <div class="header">
      <div class="btn" @click="goCityList">
        <van-icon name="arrow-down" />
        <div class="title">{{ areaName }}</div>
      </div>
    </div>
    <div class="list">
      <template v-if="list.length > 0">
        <div v-for="item in list" :key="item.id" class="item">
          <div class="avatar" @click="goSalesmanInfo(item.id)">
            <img :src="item.headImgUrl" class="img">
          </div>
          <div class="cont" @click="goSalesmanInfo(item.id)">
            <div class="head">
              <div class="name">{{ item.realName }}</div>
              <div class="sex">
                <img v-if="item.sex == 1" src="@/assets/male@2x.png" class="icon">
                <img v-else src="@/assets/female@2x.png" class="icon">
                <span class="age">{{ item.age }}岁</span>
              </div>
            </div>
            <div class="des">
              <div class="p">已成交订单数：{{ item.orderCount }}</div>
              <div class="p">从业经验：{{ item.workExp }}年</div>
            </div>
          </div>
          <div class="per">
            <div v-if="targetId" class="btn" @click="goChangeSalesman(item)">聊一聊</div>
            <div v-else-if="item.couldChat" class="btn" @click="goChatRoom(item)">
              <span v-if="item.chatState">继续沟通</span>
              <span v-else>聊一聊</span>
            </div>
            <div v-else class="btn disabled" bindtap="stopGo">聊一聊</div>
          </div>
        </div>
      </template>
      <template v-else-if="!loading">
        <DefaultPage :title="noneTitle" style="padding-top: 200px;" />
      </template>
    </div>
    <van-dialog v-model="resonStatus" title="原因" confirm-button-color="#FF7700" show-cancel-button @confirm="goConfirm">
      <div class="reason">
        <div class="des">您还可更换{{ count }}次经理人，请谨慎操作！</div>
        <div class="textarea">
          <van-field v-model="reason" type="textarea" class="text" maxlength="50" show-word-limit placeholder="请输入更换经理人的原因..." />
        </div>
      </div>
    </van-dialog>
  </div>

</template>

<script>
import { localCityManager, changeUserFriend, countNum } from '@/api/user'
import { getOssUrl } from '@/utils/oss'
import { getLocalUserInfo } from '@/utils/auth'
import DefaultPage from '@/components/DefaultPage'
export default {
  components: { DefaultPage },
  data() {
    return {
      list: [],
      areaName: '',
      modalName: '',
      count: 0,
      reason: '',
      resonStatus: false,
      targetId: '', // 有id表示更换经理人
      salesman: {}, // 选中要更换的经理人
      city: {}, // 当前选择城市
      locationCity: {}, // 定位城市
      userType: '', // 更换经理人时
      loading: false,
      noneTitle: '暂无数据哦~'
    }
  },
  async mounted() {
    const { salesmanId } = this.$route.query
    if (salesmanId) {
      this.targetId = salesmanId
      this.userType = 4
      const res = await countNum()
      this.count = res.datas
    }
    const { city, locationCity } = getLocalUserInfo()
    this.city = city
    this.locationCity = locationCity
    this.areaName = city.areaName || '定位失败'
    this.getList()
  },
  methods: {
    goSalesmanInfo(saleId) {
      this.$router.push({ name: 'salesmanInfo', params: { saleId }})
    },
    goChangeSalesman(item) {
      this.salesman = item
      this.resonStatus = true
    },
    goCityList() {
      this.$router.push({ name: 'cityList' })
    },
    goChatRoom(item) {
      const sendHelloMsg = !item.chatState // 是否发送问候语
      this.$router.push({ name: 'chatRoom', params: { your: item.eaUserName, sendHelloMsg }})
    },
    async goConfirm() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const { reason, targetId, salesman } = this
      await changeUserFriend({
        reason,
        targetId,
        targetUserId: salesman.id,
        bindSalesman: true
      })
      loading.clear()
      // app.globalData.sendHelloMsg = true
      this.$router.replace({ name: 'chatRoom', params: { your: salesman.eaUserName, sendHelloMsg: true }})
    },
    async getList() {
      this.loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const cityCodeBySwitch = this.city.cityCode
      const { cityCode, adCode, latitude, longitude } = this.locationCity
      const params = {
        cityCode,
        adCode,
        latitude,
        longitude,
        cityCodeBySwitch
      }
      if (this.userType) {
        params.userType = this.userType
      }
      const res = await localCityManager(params)
      if (res.resp_code === 1) {
        this.noneTitle = res.resp_msg
      }
      const list = res.datas || []
      for (let i = 0; i < list.length; i++) {
        list[i].headImgUrl = await getOssUrl(list[i].headImgUrl)
      }
      this.loading.clear()
      this.loading = false
      this.list = list
    }
  }
}
</script>

<style lang="scss" scoped>
.city-manager {
    padding: 16px;
    .header {
        display: flex;
        .btn {
            display: flex;
            align-items: center;
            padding: 4px 10px;
            border: 1px solid #CCCCCC;
            border-radius: 4px;
            .icon {
                width: 8px;
                height: 6px;
            }
            .title {
                padding-left: 5px;
                font-size: 12px;
                color: #666666;
            }
        }
    }
    .list {
        .item {
            display: flex;
            padding: 15px 0 10px 0;
            border-bottom: 1px solid #F3F3F5;
            &:last-child {
                border: 0;
            }
            .avatar {
                width: 40px;
                height: 40px;
                border-radius: 50px;
                .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                }
            }
            .cont {
                flex: 1;
                padding-left: 10px;
                .head {
                    display: flex;
                    align-items: center;
                    .name {
                        padding-right: 8px;
                        font-size: 16px;
                    }
                    .sex {
                        display: flex;
                        align-items: center;
                        padding: 3px 5px;
                        border-radius: 4px;
                        background: #F3F3F3;
                        .icon {
                            width: 15px;
                            height: 15px;
                        }
                        .age {
                            padding-left: 5px;
                            font-size: 12px;
                            color: #666666;
                        }
                    }
                }
                .des {
                    font-size: 12px;
                    color: #999999;
                    .p {
                        padding-top: 5px;
                    }
                }
            }
            .per {
                padding-top: 15px;
                .btn {
                    width: 70px;
                    padding: 7px 0;
                    font-size: 12px;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 50px;
                    background: linear-gradient(136deg, #FFB367 0%, #FF7700 100%);
                    &.disabled {
                        background: #E6E6E6;
                    }
                }
            }
        }
    }
}

.reason {
    background: #fff;
    .des {
      padding: 15px 0 5px 0;
      color: red;
      text-align: center;
    }
    .textarea {
      text-align: left;
      padding: 10px;
      .text {
          width: 100%;
          padding: 10px;
          border: 1px solid #eee;
          border-radius: 4px;
          box-sizing: border-box;
      }
    }
}
</style>
